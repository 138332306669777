import AppRouter from "./router/AppRouter";

const App = () => {
  console.log(process.env.REACT_APP_HOST_ENV);
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
};

export default App;
