import "./NavBar.scss";
import logo from "../../images/logo.png";
// import logo2x from "../../images/logo2x.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useWindowSize from "../../utils/useWindowSize";

const NavBar = (props) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { width, height } = useWindowSize();

  useEffect(() => {
    if (menuIsOpen) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [menuIsOpen]);
  return (
    <header
      className={`nav-bar ${props.addClass} ${menuIsOpen ? "open" : "closed"}`}
    >
      <Link className={`${menuIsOpen ? "hideLogo" : "showLogo"}`} to="/">
        <img
          src={logo}
          // srcSet={`${logo2x} 2x`}
          className="logo"
          alt="logo"
          ref={props.logoRef}
        />
      </Link>
      <div className="menu-logo-parent">
        <div className="menu-logo" onClick={() => setMenuIsOpen(!menuIsOpen)} />
      </div>
      <ul
        className={`menu-items ${menuIsOpen ? "open" : "closed"}`}
        style={{ maxHeight: height - 96 }}
      >
        <li className="nav__menu-item ml-32 collapsed-link">
          <Link to="/team">Our team</Link>
        </li>
        <li className="nav__menu-item ml-32 collapsed-link">
          <Link to="/careers">Careers</Link>
        </li>
        {/* <li className="nav__menu-item ml-32 collapsed-link">
          <Link to="/foundation">Perveen Shakir Foundation</Link>
        </li> */}
      </ul>
    </header>
  );
};

export default NavBar;
