import useWindowSize from "../../utils/useWindowSize";
import "./LandingPage.scss";
import logo from "../../images/logo.png";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
// import logo2x from "../../images/logo2x.png";
// import versus from "../../images/verse2x.png";
// import signature from "../../images/signature.png";
// import signature2x from "../../images/signature2x.png";
import Navbar from "../../components/NavBar/NavBar";
import { useEffect, useRef, useState } from "react";
import { getDeviceType } from "../../utils/helpers";
import { Link } from "react-router-dom";

const LandingPage = () => {
  return (
    <div className="landing-page flex-column page general-padding">
      <div className="content">
        <br />
        <br />
        <div className="white-color">
          THIS WEBSITE IS CURRENTLY UNDER CONSTRUCTION.
          <br />
          <br />
          <Link className="white-color" to="/team">
            PLEASE CLICK HERE TO VISIT OUR TEAM.
          </Link>
        </div>
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
      </div>
    </div>
  );
};

export default LandingPage;
