import iftikhar from "../../images/iftikhar.png";
import munawar from "../../images/munawar.png";
import dummy from "../../images/dummy.png";
// import Image3 from "../../images/Biography/pic3.png";
// import Image4 from "../../images/Biography/pic4.png";
// import ImageEgg from "../../images/EggImageBiography.png";
import BackToTop from "react-back-to-top-button";
import TopIcon from "../../images/Scroll/topIcon.png";
import "./OurTeam.scss";

const Biography = () => {
  return (
    <div className="general-page flex-column page general-padding">
      <div className="content">
        <BackToTop
          showOnScrollUp={false}
          showAt={300}
          speed={1500}
          easing="easeInOutQuint"
        >
          <img className="icon-top" src={TopIcon} alt="back to top" />
        </BackToTop>
        {/* <div className="details trust-details">
          <h1 className="container-heading trust">
            <span>MEET OUR TEAM</span>
          </h1>
        </div> */}
      </div>
      <div className="biography-item odd">
        <div className="content">
          <div className="details">
            <h1 className="container-heading biography">MIAN IFTIKHAR ALI</h1>
            <span className="container-heading biography biography-topic">
              MANAGING DIRECTOR
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Mian Iftikhar Ali has qualified in BCS (IT) and is the real
                backbone behind our company. He has been a part of Metaflex
                since 2008 and has been handlng account, sales tax, bank and
                recruitment matters from the core level of this company.
                Moreover, he is also overlooking store and purchasing and has
                led our company towards great horizons. He is currently serving
                as a Managing Director.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={iftikhar} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item even">
        <div className="content">
          <div className="details">
            <h1 className="container-heading biography">MUHAMMAD ASIM IQBAL</h1>
            <span className="container-heading biography biography-topic">
              PROJECT MANAGER
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Muhammad Asim, an engineer from the University of Peshawar, has
                been running the company for two decades now. He started his
                career of whatever from Caltex and then moved onto different
                companies likes Admore Attock. He then went on to diversifying
                our business and making CNS Routers alongwith interior works
                with renowned companies like LG, Bata and Samsung. In year, 2012
                we began expanding the business further and added more companies
                under their umbrella like Oilco, Vital, exceed, quality 1,
                huawei, techno. Mr. Asim has been the spotlight man behind all
                these developments. He is currently serving as a Project
                Manager.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={dummy} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item odd">
        <div className="content">
          <div className="details">
            <h1 className="container-heading biography">MUNAWAR ABBAS</h1>
            <span className="container-heading biography biography-topic">
              DIRECTOR FINANCE
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Munawar, who is working as a finance manager in Metaflix science
                system, is an ACCA finalist by education. He also looks over the
                HR department of the company. He is currently serving as
                Director Finance.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={munawar} alt="test" />
          </div>
        </div>
      </div>
      <div className="biography-item even">
        <div className="content">
          <div className="details">
            <h1 className="container-heading biography">OSAMA AMEER ASIM</h1>
            <span className="container-heading biography biography-topic">
              PRODUCTION MANAGER
            </span>
            <div className="trust-body biography">
              <p className="body-text biography">
                Osama Asim has graduated in engineering from Queen Mary
                University of London, right after which he joined Metaflix and
                has been working as a permanent member for the company ever
                since. His immense contributions have helped the company towards
                better growth. He is currently serving as a Production Manager.
              </p>
            </div>
          </div>
          <div className="photo biography">
            <img src={dummy} alt="test" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Biography;
