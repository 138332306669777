import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import OurTeam from "../pages/OurTeam/OurTeam";
import ContactUs from "../pages/ContactUs/ContactUs";
import GalleryImages from "../pages/GalleryImages/GalleryImages";
import LandingPage from "../pages/LandingPage/LandingPage";
import Trust from "../pages/Trust/Trust";
import PublicRoute from "./PublicRoute";
import Footer from "../components/Footer/Footer";
import Foundation from "../pages/Foundation/Foundation";
import GalleryVideos from "../pages/GalleryVideos/GalleryVideos";
import Books from "../pages/Books/Books";
import Awards from "../pages/Awards/Awards";

const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/" exact component={LandingPage} />
        {/* <PublicRoute path="/gallery/images" exact component={GalleryImages} /> */}
        <PublicRoute path="/team" exact component={OurTeam} />
        {/* <PublicRoute path="/trust" exact component={Trust} />
        <PublicRoute path="/contact" exact component={ContactUs} /> */}
        {/* <PublicRoute path="/foundation" exact component={Foundation} /> */}
        {/* <PublicRoute path="/gallery/videos" exact component={GalleryVideos} />
        <PublicRoute path="/books/:bookName/:bookId" exact component={Books} />
        <PublicRoute path="/awards" exact component={Awards} /> */}
      </Switch>
      <Footer />
    </Router>
  );
};

export default AppRouter;
