import { Fragment } from "react";
import { Route } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      component={(props) => (
        <Fragment>
          <NavBar />
          <Component {...props} />
        </Fragment>
      )}
    />
  );
};

export default PublicRoute;
